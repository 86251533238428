// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-brand-page-js": () => import("./../../../src/templates/brandPage.js" /* webpackChunkName: "component---src-templates-brand-page-js" */),
  "component---src-templates-business-page-js": () => import("./../../../src/templates/businessPage.js" /* webpackChunkName: "component---src-templates-business-page-js" */),
  "component---src-templates-shared-page-js": () => import("./../../../src/templates/sharedPage.js" /* webpackChunkName: "component---src-templates-shared-page-js" */),
  "component---src-templates-sport-page-js": () => import("./../../../src/templates/sportPage.js" /* webpackChunkName: "component---src-templates-sport-page-js" */)
}

