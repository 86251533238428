import React from 'react'
import GlobalContextProvider from './src/context/GlobalContextProvider'

import './src/normalize.css'
import './src/popup.css'

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}


export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
}


// export const onServiceWorkerUpdateFound = () => {
//   window.location.reload();
// }




// export const onClientEntry = () => {
//   window.addEventListener('beforeinstallprompt', (event) => {
//     event.preventDefault();
//   })
// };




// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `This application has been updated. ` +
//       `Reload to display the latest version?`
//   )
//   if (answer === true) {
//     window.location.reload()
//   }
// }

// export const onServiceWorkerUpdateFound = () => {
//   if (
//     window.confirm(
//       "This site has been updated with new data. Do you wish to reload the site to get the new data?"
//     )
//   ) {
//     window.location.reload();
//   }
// };
// export const onServiceWorkerUpdateFound = () => alert('onServiceWorkerUpdateFound');

// export const onServiceWorkerUpdateReady = () => window.location.reload();
// export const onServiceWorkerUpdateReady = () => alert('onServiceWorkerUpdateReady');


// export const onClientEntry = () => alert('onClientEntry');





// export const onInitialClientRender = () => alert('onInitialClientRender');