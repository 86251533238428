import React from 'react'
export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  menu: false,
  swipeNav: false,

  // windowWidth: 0,
  // windowHeight: 0,
  windowWidth: undefined,
  windowHeight: undefined,
  windowMode: null,

  mouseX: null,
  mouseY: null,

  /** LOCATION / STATE - SHAREDPAGE TEMPLATE MANAGE **/
  // currentSection: false,

  /** SHIPPING MAP **/
  continent: '',

  /** BRAND SECTION **/
  playStartBrand: false,

  /** SPORT SECTION **/
  playStartSport: false,

  /** BUSINESS SECTION **/
  playStartBusiness: false,
}

function reducer(state, action) {
  switch (action.type) {

    case 'TOGGLE_MENU': {
      return {
        ...state,
        menu: !state.menu,
      }
    }

    case 'SWIPE_BODY_CLASS': {
      return {
        ...state,
        swipeNav: action.value,
      }
    }

    case 'WINDOW_WIDTH': {
      return {
        ...state,
        windowWidth: action.value
      }
    }

    case 'WINDOW_HEIGHT': {
      return {
        ...state,
        windowHeight: action.value
      }
    }

    case 'WINDOW_MODE': {
      return {
        ...state,
        windowMode: action.value
      }
    }

    case 'MOUSE_X': {
      return {
          ...state,
          mouseX: action.value
      }
    }

    case 'MOUSE_Y': {
        return {
            ...state,
            mouseY: action.value
        }
    }

    // case 'CURRENT_SECTION': {
    //     return {
    //         ...state,
    //         currentSection: action.value
    //     }
    // }

    case 'PLAY_START_BRAND': {
      return {
        ...state,
        playStartBrand: !state.playStartBrand,
      }
    }

    case 'PLAY_START_SPORT': {
      return {
        ...state,
        playStartSport: !state.playStartSport,
      }
    }

    case 'PLAY_START_BUSINESS': {
      return {
        ...state,
        playStartBusiness: !state.playStartBusiness,
      }
    }
    
    case 'SHIPPING_CONTINENT': {
      return {
        ...state,
        continent: action.value
      }
    }


  default:
      throw new Error('Bad Action Type')
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}
export default GlobalContextProvider